import { SimulationData } from '../../../helpers/interfaces/dataTypes.interface';
import CustomSignatureContent from '../../Containers/CustomSignatureContent';
import GasEstimate from '../Transfers/GasEstimate';
import { SplitNetBalanceChanges } from '../../../helpers/methods';

interface IENSSignatureProps {
  sourceUrl: string | undefined;
  transactionSimulation: SimulationData | undefined;
  network: string;
}

const ENSSignature: React.FC<IENSSignatureProps> = ({
  sourceUrl,
  transactionSimulation,
  network,
}) => {
  const { validators } = SplitNetBalanceChanges(
    transactionSimulation?.netBalanceChanges ?? [],
    transactionSimulation?.from ?? '',
  );

  const iconUrl = `/dAppDomainIcons/ens.png`;

  return (
    <div className="mb-10">
      <CustomSignatureContent
        messageHeader="Message:"
        message="This is a request to register from ENS"
        logo={iconUrl}
        counterpartyAction="Verifying To"
        sourceUrl={sourceUrl}
      />
      <GasEstimate
        className="ml-8"
        hexChainId={network}
        usdPrice={validators[0]?.usdValue ?? -1}
        value={validators[0]?.value}
        decimals={validators[0]?.decimals}
      />
    </div>
  );
};
export default ENSSignature;
