interface LinkoutButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean | undefined;
  className?: string | undefined;
}

const LinkoutButton: React.FC<LinkoutButtonProps> = ({
  onClick,
  disabled,
  className,
}) => {
  return (
    <button className={className} onClick={onClick} disabled={disabled}>
      <svg
        width="25"
        height="25"
        style={{ display: 'inline' }}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="12.5" cy="12.5" r="12.5" fill="#EFEEF0" />
        <path
          d="M13.3125 11.6875L17.375 7.625M17.375 7.625H14.6667M17.375 7.625V10.3333M17.375 13.5833V16.2917C17.375 16.579 17.2609 16.8545 17.0577 17.0577C16.8545 17.2609 16.579 17.375 16.2917 17.375H8.70833C8.42102 17.375 8.14547 17.2609 7.9423 17.0577C7.73914 16.8545 7.625 16.579 7.625 16.2917V8.70833C7.625 8.42102 7.73914 8.14547 7.9423 7.9423C8.14547 7.73914 8.42102 7.625 8.70833 7.625H11.4167"
          stroke="#312E45"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
};

export default LinkoutButton;
