import { useState, useMemo } from 'react';
import { getAddressLabel } from '../helpers/methods';

export const useAddressLabels = (
  addressList: string[],
  network: string,
  existingMapping?: Map<string, Promise<string | null>>,
  debug: number = 0
) => {
  const addressSet = new Set<string>(addressList);
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<Map<string, string | null>>();
  const [logos, setLogos] = useState<Map<string, string | null>>();
  const [error, setError] = useState<unknown | undefined>(undefined);

  if (debug === 111) {
    console.log('useAddressLabels', debug);
  }

  useMemo(() => {
    setLoading(true);
    const getAddressLabelMap = async () => {
      //console.log('getAddressLabelMap', addressSet, network, existingMapping);
      try {
        // grab address labels for each address in the set and return a promise
        const addressSetArray = Array.from(addressSet);
        //console.log('addressSetArray', addressSetArray);

        let promisesToSettle: Promise<{
          label: string | null;
          logoUrl: string | null;
        }>[];

        //If we have lookups already in progress in the existingMapping, merge these promises in to resolve with the others
        if (existingMapping) {
          //console.log('1111111111', addressSetArray);
          //extract pending lookups and their mapped addresses as arrays from the map
          const pendingLookups = Array.from(existingMapping.values());
          const existingMappingAddresses = Array.from(existingMapping.keys());

          //Remove pending promises from the lookups
          const lookupAddresses = addressSetArray.filter(
            (address: string) => !existingMappingAddresses.includes(address)
          );

          //merge the already pending promises with our lookups
          promisesToSettle = [
            ...lookupAddresses.map((address: string) =>
              getAddressLabel(address, network, 3).then((result) => ({
                label: result?.label || null,
                logoUrl: result?.logoUrl || null,
              }))
            ),
            ...pendingLookups.map((promise) =>
              promise.then((result) => ({
                label: typeof result === 'string' ? result : null,
                logoUrl: null,
              }))
            ),
          ];
        } else {
          //console.log('2222222222', addressSetArray);
          promisesToSettle = addressSetArray.map((address: string) =>
            getAddressLabel(address, network, 4).then((result) => ({
              label: result?.label || null,
              logoUrl: result?.logoUrl || null,
            }))
          );
        }

        const settledPromises: PromiseSettledResult<{
          label: string | null;
          logoUrl: string | null;
        }>[] = await Promise.allSettled(promisesToSettle);

        //Employ a type guard to see which promises fulfilled and convert the type accordingly
        const isFilled = <
          T extends { label: string | null; logoUrl: string | null }
        >(
          promiseToSettle: PromiseSettledResult<T>
        ): promiseToSettle is PromiseFulfilledResult<T> =>
          promiseToSettle.status === 'fulfilled';

        //If the promise resolved then map its value, if it failed map null
        const addressLabelsAndLogos: {
          label: string | null;
          logoUrl: string | null;
        }[] = settledPromises.map(
          (
            promise: PromiseSettledResult<{
              label: string | null;
              logoUrl: string | null;
            }>
          ) =>
            isFilled(promise) ? promise.value : { label: null, logoUrl: null }
        );

        //Return a mapping of addresses and labels if returned
        const labeledAddressMap = new Map<string, string | null>(
          Array.from(addressSet).map((address: string, i: number) => [
            address,
            addressLabelsAndLogos[i].label,
          ])
        );

        const addressWithLogoMap = new Map<string, string | null>(
          Array.from(addressSet).map((address: string, i: number) => [
            address,
            addressLabelsAndLogos[i].logoUrl,
          ])
        );

        ////console.log('labeledAddressMap', labeledAddressMap);
        ////console.log('addressWithLogoMap', addressWithLogoMap);

        setData(labeledAddressMap);
        setLogos(addressWithLogoMap);
        setError(undefined);
        setLoading(false);
      } catch (e) {
        //If an uncaught error occurred, log and return empty map
        console.error(e);
        const labeledAddressMap = new Map<string, string | null>(
          Array.from(addressSet).map((address) => [address, null])
        );

        setData(labeledAddressMap);
        setError(e);
        setLoading(false);
      }
    };
    getAddressLabelMap();
  }, []);

  return { data, loading, error, logos };
};
