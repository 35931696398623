import { useState } from 'react';
import { ERC1155Data } from '../../../helpers/interfaces/dataTypes.interface';
import { useAddressLabels } from '../../../hooks/useAddressLabel';
//import Address from '../../Atoms/Address';
import TransactionCard from '../../Containers/TransactionCard';

export interface INftApproveProps {
  balanceChange: ERC1155Data;
  network: string;
}

//TODO: Once the API changes are in, dynamically swap token name with collection name if setApprovalForAll called
const NftApprove: React.FC<INftApproveProps> = ({ balanceChange, network }) => {
  const {
    data: addressLabelMap,
    logos: logoMap,
    loading,
  } = useAddressLabels(
    [balanceChange.to, balanceChange.from],
    network,
    undefined,
    3
  );

  //const divider = <div className="w-[145px] h-[1px] bg-gray-200" />;
  ////console.log('balanceChange.contract', balanceChange.contract);
  ////console.log('img', logoMap?.get(balanceChange.contract));

  const imageUrl =
    balanceChange?.token?.imageUrl ?? logoMap?.get(balanceChange.contract);
  const isValidImage = !!imageUrl;

  //TODO: abstract to its own component. This is used in both NFT cards
  const NftImage: React.FC = () => {
    const [didImageError, setDidImageError] = useState<boolean>(false);

    const ContentImage: JSX.Element = (
      <img
        src={isValidImage ? imageUrl : 'invalid'}
        onError={() => setDidImageError(true)}
        className="h-[32px] w-[32px] rounded-full"
      />
    );
    if (!didImageError) {
      return <>{ContentImage}</>;
    }
    return <img src={'/images/no-nft.png'} />;
  };

  ////console.log('NftApprove', { balanceChange, addressLabelMap });

  const tokenName =
    balanceChange.token?.name ??
    addressLabelMap?.get(balanceChange.contract) ??
    'UNKNOWN';

  return (
    <TransactionCard loading={loading}>
      <div className="interacting-item-label">
        <div className="interacting-item-nft">
          <NftImage />
        </div>
        <div title={tokenName}>{tokenName}</div>
      </div>
      <div className="interacting-item-value">
        <div className="approving-value">{balanceChange.value}</div>
      </div>
    </TransactionCard>
  );
  /*
  return (
    <TransactionCard loading={loading}>
      <div className="grid grid-cols-2 h-full">
        <div className="flex flex-col justify-end">
          <NftImage />
        </div>
        <div className="flex flex-col justify-between">
          <div>
            <div className="font-ClashDisplay text-secondary-800 text-3xl font-medium">
              {balanceChange.value === 'all' ? 'Collection' : 'Token'}
            </div>
            <span className="font-Manrope">{balanceChange.token?.name}</span>
          </div>
          <div>
            <span className="font-Manrope text-gray-400 font-thin text-xs">
              Amount
            </span>
            <div>{balanceChange.value}</div>
          </div>
          {divider}
          <div>
            <span className="font-Manrope text-gray-400 font-thin text-xs">
              To
            </span>
            <Address
              network={network}
              position="top center"
              labeledAddress={addressLabelMap?.get(balanceChange.to)}
              address={balanceChange.to}
            />
          </div>
        </div>
      </div>
    </TransactionCard>
  );*/
};

export default NftApprove;
