interface IFireButtonProps {
  className?: string;
  isSecondary?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement> | undefined;
  children: string;
}

const FireButton = ({ ...props }: IFireButtonProps) => {
  const primaryButtonStyle =
    'text-white h-[44px] rounded-full text-lg font-ClashDisplay transition ease-in-out hover:scale-110 duration-300 focus:outline-none';

  const buttonColor = props.isSecondary
    ? 'bg-fire_purple-200'
    : 'bg-secondary-800';
  return (
    <button
      className={`${buttonColor} ${primaryButtonStyle} ${props.className}`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default FireButton;
