//NOTE: When adding addresses to this file, they must be in toLowerCase format or the search won't work
export const contracts: any = {
  '0x1': {
    '0x1e0049783f008a0085193e00003d00cd54003c71': {
      name: 'OpenSea',
      category: '',
    },
    '0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb': {
      name: 'CryptoPunks',
      category: 'NFT Collection',
    },

    '0xbc4ca0eda7647a8ab7c2061c2e118a18a936f13d': {
      name: 'Bored Ape Yacht Club',
      category: 'NFT Collection',
    },

    '0x8cd8155e1af6ad31dd9eec2ced37e04145acfcb3': {
      name: 'Cupcats',
      category: 'NFT Collection',
    },

    '0xd31fc221d2b0e0321c43e9f6824b26ebfff01d7d': {
      name: 'Brotchain',
      category: 'NFT Collection',
    },

    '0xc0cf5b82ae2352303b2ea02c3be88e23f2594171': {
      name: 'The Fungible by Pak',
      category: 'NFT Collection',
    },

    '0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0': {
      name: 'SuperRare',
      category: 'NFT Collection',
    },

    '0xa7ee407497b2aeb43580cabe2b04026b5419d1dc': {
      name: 'Superlative Secret Society (Official)',
      category: 'NFT Collection',
    },

    '0xa0c38108bbb0f5f2fb46a2019d7314cce38f3f22': {
      name: 'ArcadeNFT',
      category: 'NFT Collection',
    },

    '0xc3f733ca98e0dad0386979eb96fb1722a1a05e69': {
      name: 'Official MoonCats - Acclimated',
      category: 'NFT Collection',
    },

    '0xe7191c896d59a9c39965e16c5184c44172ec9cf9': {
      name: 'The Soliders of the Metaverse',
      category: 'NFT Collection',
    },

    '0x61598e2e6b012293cb6012b055ad77aa020e0206': {
      name: 'BroadcastersNFT',
      category: 'NFT Collection',
    },

    '0xa08126f5e1ed91a635987071e6ff5eb2aeb67c48': {
      name: 'Galaxy-Eggs',
      category: 'NFT Collection',
    },

    '0x219b8ab790decc32444a6600971c7c3718252539': {
      name: 'Sneaky Vampire Syndicate',
      category: 'NFT Collection',
    },

    '0x329fd5e0d9aad262b13ca07c87d001bec716ed39': {
      name: 'Adventure Cards',
      category: 'NFT Collection',
    },

    '0xa7206d878c5c3871826dfdb42191c49b1d11f466': {
      name: 'LOSTPOETS',
      category: 'NFT Collection',
    },

    '0xa66f3bd98b4741bad68bcd7511163c6f855d2129': {
      name: 'Impermanent Digital',
      category: 'NFT Collection',
    },

    '0xbad6186e92002e312078b5a1dafd5ddf63d3f731': {
      name: 'Anonymice',
      category: 'NFT Collection',
    },

    '0x33825285eb66c11237cc68cc182c1e9bf01ba00b': {
      name: 'The Glitches NFT',
      category: 'NFT Collection',
    },

    '0x57a204aa1042f6e66dd7730813f4024114d74f37': {
      name: 'CyberKongz',
      category: 'NFT Collection',
    },

    '0x0ee24c748445fb48028a74b0ccb6b46d7d3e3b33': {
      name: 'NAH FUNGIBLE BONES',
      category: 'NFT Collection',
    },

    '0xd153f0014db6d1f339c6340d2c9f59214355d9d7': {
      name: 'Crypto Hobos',
      category: 'NFT Collection',
    },

    '0x943cc300dd938d8490d33d794507fedc25c49002': {
      name: 'uunicorns',
      category: 'NFT Collection',
    },

    '0xe433e90c5b898819544346e73a501d9e8013dbd8': {
      name: 'CurrencyPunks',
      category: 'NFT Collection',
    },

    '0x050dc61dfb867e0fe3cf2948362b6c0f3faf790b': {
      name: 'Wrapped PixelMap',
      category: 'NFT Collection',
    },

    '0x36d30b3b85255473d27dd0f7fd8f35e36a9d6f06': {
      name: '888 inner circle',
      category: 'NFT Collection',
    },

    '0xfb61bd914d4cd5509ecbd4b16a0f96349e52db3d': {
      name: 'APE DAO REMIX',
      category: 'NFT Collection',
    },

    '0x1cb1a5e65610aeff2551a50f76a87a7d3fb649c6': {
      name: 'CrypToadz by GREMPLIN',
      category: 'NFT Collection',
    },

    '0x73da73ef3a6982109c4d5bdb0db9dd3e3783f313': {
      name: 'My Curio Cards',
      category: 'NFT Collection',
    },

    '0xecdd2f733bd20e56865750ebce33f17da0bee461': {
      name: 'The CryptoDads',
      category: 'NFT Collection',
    },

    '0xb5f3dee204ca76e913bb3129ba0312b9f0f31d82': {
      name: 'Omnimorphs',
      category: 'NFT Collection',
    },

    '0x2d0ee46b804f415be4dc8aa1040834f5125ebd2e': {
      name: 'Dapper Dinos NFT',
      category: 'NFT Collection',
    },

    '0x8630cdeaa26d042f0f9242ca30229b425e7f243f': {
      name: 'Claylings',
      category: 'NFT Collection',
    },

    '0xc9e2c9718ff7d3129b9ac12168195507e4275cea': {
      name: '0xVampire Project',
      category: 'NFT Collection',
    },

    '0xf36446105ff682999a442b003f2224bcb3d82067': {
      name: 'Axolittles',
      category: 'NFT Collection',
    },

    '0x4f89cd0cae1e54d98db6a80150a824a533502eea': {
      name: 'PEACEFUL GROUPIES',
      category: 'NFT Collection',
    },

    '0x99c2546aebc070fb1f286a346ec4d25e70533474': {
      name: 'Quadrums',
      category: 'NFT Collection',
    },

    '0x11739d7bd793543a6e83bd7d8601fcbcde04e798': {
      name: 'Wrapped Strikers',
      category: 'NFT Collection',
    },

    '0x3702f4c46785bbd947d59a2516ac1ea30f2babf2': {
      name: 'GalaxyFightClub',
      category: 'NFT Collection',
    },

    '0x7ea3cca10668b8346aec0bf1844a49e995527c8b': {
      name: 'CyberKongz VX',
      category: 'NFT Collection',
    },

    '0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270': {
      name: 'Art Blocks (Combined)',
      category: 'NFT Collection',
    },

    '0x80416304142fa37929f8a4eee83ee7d2dac12d7c': {
      name: 'Generative Masks',
      category: 'NFT Collection',
    },

    '0x76be3b62873462d2142405439777e971754e8e77': {
      name: 'Parallel',
      category: 'NFT Collection',
    },

    '0x60e4d786628fea6478f785a6d7e704777c86a7c6': {
      name: 'Mutant Ape Yacht Club',
      category: 'NFT Collection',
    },

    '0xff9c1b15b16263c61d017ee9f65c50e4ae0113d7': {
      name: 'Loot',
      category: 'NFT Collection',
    },

    '0xc92ceddfb8dd984a89fb494c376f9a48b999aafc': {
      name: 'Creature World',
      category: 'NFT Collection',
    },

    '0x3f5fb35468e9834a43dca1c160c69eaae78b6360': {
      name: 'Koala Intelligence Agency',
      category: 'NFT Collection',
    },

    '0xbd3531da5cf5857e7cfaa92426877b022e612cf8': {
      name: 'Pudgy Penguins',
      category: 'NFT Collection',
    },

    '0x343f999eaacdfa1f201fb8e43ebb35c99d9ae0c1': {
      name: 'Lonely Aliens Space Club',
      category: 'NFT Collection',
    },

    '0xaadc2d4261199ce24a4b0a57370c4fcf43bb60aa': {
      name: 'The Currency',
      category: 'NFT Collection',
    },

    '0xe785e82358879f061bc3dcac6f0444462d4b5330': {
      name: 'World Of Women',
      category: 'NFT Collection',
    },

    '0x3f4a885ed8d9cdf10f3349357e3b243f3695b24a': {
      name: 'Incognito',
      category: 'NFT Collection',
    },

    '0x7afeda4c714e1c0a2a1248332c100924506ac8e6': {
      name: 'FVCK_CRYSTAL',
      category: 'NFT Collection',
    },

    '0x49ac61f2202f6a2f108d59e77535337ea41f6540': {
      name: 'Star Sailor Siblings',
      category: 'NFT Collection',
    },

    '0x88091012eedf8dba59d08e27ed7b22008f5d6fe5': {
      name: 'Secret Society of Whales',
      category: 'NFT Collection',
    },

    '0xbe6e3669464e7db1e1528212f0bff5039461cb82': {
      name: 'Wicked Apes Bone Club',
      category: 'NFT Collection',
    },

    '0x7bd29408f11d2bfc23c34f18275bbf23bb716bc7': {
      name: 'Meebits',
      category: 'NFT Collection',
    },

    '0x8d04a8c79ceb0889bdd12acdf3fa9d207ed3ff63': {
      name: 'Blitmap',
      category: 'NFT Collection',
    },

    '0x1a92f7381b9f03921564a437210bb9396471050c': {
      name: 'Cool Cats',
      category: 'NFT Collection',
    },

    '0x8184a482a5038b124d933b779e0ea6e0fb72f54e': {
      name: 'Wanderers',
      category: 'NFT Collection',
    },

    '0x3b3ee1931dc30c1957379fac9aba94d1c48a5405': {
      name: 'FND NFT',
      category: 'NFT Collection',
    },

    '0xdda32aabbbb6c44efc567bac5f7c35f185338456': {
      name: 'Cypher',
      category: 'NFT Collection',
    },

    '0x80adb36595239fe918c7d118c1f81e07d070801a': {
      name: 'HeavenComputer',
      category: 'NFT Collection',
    },

    '0xd4d871419714b778ebec2e22c7c53572b573706e': {
      name: 'Stoner Cats',
      category: 'NFT Collection',
    },

    '0x797a48c46be32aafcedcfd3d8992493d8a1f256b': {
      name: 'MintPassFactory',
      category: 'NFT Collection',
    },

    '0xf621b26ce64ed28f42231bcb578a8089f7958372': {
      name: 'Bored Mummy Waking Up',
      category: 'NFT Collection',
    },

    '0x375ea781c49eafedde07afe6196f885761f166ae': {
      name: 'CryptoTrunks',
      category: 'NFT Collection',
    },

    '0x4a8b01e437c65fa8612e8b699266c0e0a98ff65c': {
      name: 'Space Poggers',
      category: 'NFT Collection',
    },

    '0xf4ee95274741437636e748ddac70818b4ed7d043': {
      name: 'The Doge Pound',
      category: 'NFT Collection',
    },

    '0x91f7bb6900d65d004a659f34205beafc3b4e136c': {
      name: 'Derpy Birds',
      category: 'NFT Collection',
    },

    '0x3bf2922f4520a8ba0c2efc3d2a1539678dad5e9d': {
      name: '0N1 Force',
      category: 'NFT Collection',
    },

    '0x18df6c571f6fe9283b87f910e41dc5c8b77b7da5': {
      name: 'Animetas',
      category: 'NFT Collection',
    },

    '0xccc441ac31f02cd96c153db6fd5fe0a2f4e6a68d': {
      name: 'FLUF',
      category: 'NFT Collection',
    },

    '0xb159f1a0920a7f1d336397a52d92da94b1279838': {
      name: 'Royal Society',
      category: 'NFT Collection',
    },

    '0x0fb69d1dc9954a7f60e83023916f2551e24f52fc': {
      name: 'Lost Souls Sanctuary',
      category: 'NFT Collection',
    },

    '0x82c7a8f707110f5fbb16184a5933e9f78a34c6ab': {
      name: 'Emblem Vault V2',
      category: 'NFT Collection',
    },

    '0x2acab3dea77832c09420663b0e1cb386031ba17b': {
      name: 'DeadFellaz',
      category: 'NFT Collection',
    },

    '0xad9fd7cb4fc7a0fbce08d64068f60cbde22ed34c': {
      name: 'VoX Series 1',
      category: 'NFT Collection',
    },

    '0x15c2b137e59620552bd0d712fe7279cf1f47468d': {
      name: 'Glue Factory Show',
      category: 'NFT Collection',
    },

    '0x31f3bba9b71cb1d5e96cd62f0ba3958c034b55e9': {
      name: 'Party Penguins',
      category: 'NFT Collection',
    },

    '0x763864f1a74d748015f45f7c1181b60e62e40804': {
      name: 'Dope Shibas',
      category: 'NFT Collection',
    },

    '0xd0a07a76746707f6d6d36d9d5897b14a8e9ed493': {
      name: 'Pixel Vault Founder`s DAO',
      category: 'NFT Collection',
    },

    '0xbace7e22f06554339911a03b8e0ae28203da9598': {
      name: 'CryptoArte',
      category: 'NFT Collection',
    },

    '0x50f5474724e0ee42d9a4e711ccfb275809fd6d4a': {
      name: 'Sandbox`s LANDs',
      category: 'NFT Collection',
    },

    '0x0b0b186841c55d8a09d53db48dc8cab9dbf4dbd6': {
      name: 'Satoshibles',
      category: 'NFT Collection',
    },

    '0xda9f43015749056182352e9dc6d3ee0b6293d80a': {
      name: 'EtherLambos',
      category: 'NFT Collection',
    },

    '0x18c7766a10df15df8c971f6e8c1d2bba7c7a410b': {
      name: 'Vogu',
      category: 'NFT Collection',
    },

    '0x91673149ffae3274b32997288395d07a8213e41f': {
      name: 'JunkYardDogs',
      category: 'NFT Collection',
    },

    '0x45db714f24f5a313569c41683047f1d49e78ba07': {
      name: 'Space Punks',
      category: 'NFT Collection',
    },

    '0x3fe1a4c1481c8351e91b64d5c398b159de07cbc5': {
      name: 'SupDucks',
      category: 'NFT Collection',
    },

    '0x80a4b80c653112b789517eb28ac111519b608b19': {
      name: 'Crypto Cannabis Club',
      category: 'NFT Collection',
    },

    '0x83b7261db8c795701c6fc86d1fcd073ece940e10': {
      name: 'MembershipToken',
      category: 'NFT Collection',
    },

    '0xcab65c60d9dc47e1d450c7e9074f73f1ff75f181': {
      name: 'Proof of Steak',
      category: 'NFT Collection',
    },

    '0xef0182dc0574cd5874494a120750fd222fdb909a': {
      name: 'RumbleKongLeague',
      category: 'NFT Collection',
    },

    '0x4fece400c0d3db0937162ab44bab34445626ecfe': {
      name: 'ETH.TOWN Hero',
      category: 'NFT Collection',
    },

    '0xc70be5b7c19529ef642d16c10dfe91c58b5c3bf0': {
      name: 'Mythereum Card',
      category: 'NFT Collection',
    },

    '0xb2c0782ae4a299f7358758b2d15da9bf29e1dd99': {
      name: 'EtheremonAsset',
      category: 'NFT Collection',
    },

    '0x14b2d558687942834839874d33a73c852be5401d': {
      name: 'WWWorld Cup',
      category: 'NFT Collection',
    },

    '0xdcaad9fd9a74144d226dbf94ce6162ca9f09ed7e': {
      name: 'CryptoStrikers',
      category: 'NFT Collection',
    },

    '0xcfbc9103362aec4ce3089f155c2da2eea1cb7602': {
      name: 'CryptoCrystal',
      category: 'NFT Collection',
    },

    '0x663e4229142a27f00bafb5d087e1e730648314c3': {
      name: 'PandaEarth',
      category: 'NFT Collection',
    },

    '0xa92e3ab42c195e52c9fbf129be47ecbb03845dfd': {
      name: 'Masterpieces',
      category: 'NFT Collection',
    },

    '0x41a322b28d0ff354040e2cbc676f0320d8c8850d': {
      name: 'SuperRare',
      category: 'NFT Collection',
    },

    '0x273f7f8e6489682df756151f5525576e322d51a3': {
      name: 'MyCryptoHeroes:H{ero',
      category: 'NFT Collection',
    },

    '0xd4e4078ca3495de5b1d4db434bebc5a986197782': {
      name: 'Autoglyphs',
      category: 'NFT Collection',
    },

    '0xdceaf1652a131f32a821468dc03a92df0edd86ea': {
      name: 'MyCryptoHeroes:E{xtension',
      category: 'NFT Collection',
    },

    '0x913ae503153d9a335398d0785ba60a2d63ddb4e2': {
      name: 'SomniumSpace',
      category: 'NFT Collection',
    },

    '0x11595ffb2d3612d810612e34bc1c2e6d6de55d26': {
      name: 'Tom Sachs Rocket Components',
      category: 'NFT Collection',
    },

    '0xdf801468a808a32656d2ed2d2d80b72a129739f4': {
      name: 'Somnium Space Cubes',
      category: 'NFT Collection',
    },

    '0x181aea6936b407514ebfc0754a37704eb8d98f91': {
      name: 'yInsurenNFT',
      category: 'NFT Collection',
    },

    '0x6fc355d4e0ee44b292e50878f49798ff755a5bbc': {
      name: 'DeadHeads',
      category: 'NFT Collection',
    },

    '0x3a8778a58993ba4b941f85684d74750043a4bb5f': {
      name: 'Bulls On The Block',
      category: 'NFT Collection',
    },

    '0x00039fc9e54a042a0bdc85a0baf3a0274c3564dc': {
      name: 'The Million Dollar Punk Draw',
      category: 'NFT Collection',
    },

    '0x47e22659d9ae152975e6cbfa2eed5dc8b75ac545': {
      name: 'Fortune Media',
      category: 'NFT Collection',
    },

    '0xb9341cca0a5f04b804f7b3a996a74726923359a8': {
      name: 'OFF BLUE',
      category: 'NFT Collection',
    },

    '0xbc4ca343167a5e2d9f700cf5b6b3f849585cd6fc': {
      name: 'The 140 Collection (Twitter)',
      category: 'NFT Collection',
    },

    '0xd92e44ac213b9ebda0178e1523cc0ce177b7fa96': {
      name: 'Beeple Round 2 Open Edition',
      category: 'NFT Collection',
    },

    '0x4ad4455ad5ef891695c221e8e683efa65fabede0': {
      name: 'Bullrun Babes',
      category: 'NFT Collection',
    },

    '0xe3782b8688ad2b0d5ba42842d400f7adf310f88d': {
      name: 'BCCG',
      category: 'NFT Collection',
    },

    '0x099689220846644f87d1137665cded7bf3422747': {
      name: 'Robotos',
      category: 'NFT Collection',
    },

    '0x86f6bf16f495afc065da4095ac12ccd5e83a8c85': {
      name: 'Crazy Lizard Army',
      category: 'NFT Collection',
    },

    '0xa98771a46dcb34b34cdad5355718f8a97c8e603e': {
      name: 'EulerBeats Enigma',
      category: 'NFT Collection',
    },

    '0xf3e6dbbe461c6fa492cea7cb1f5c5ea660eb1b47': {
      name: 'FameLadySquad',
      category: 'NFT Collection',
    },

    '0xdfacd840f462c27b0127fc76b63e7925bed0f9d5': {
      name: 'Avid Lines',
      category: 'NFT Collection',
    },

    '0x8754f54074400ce745a7ceddc928fb1b7e985ed6': {
      name: 'EulerBeats Genesis',
      category: 'NFT Collection',
    },

    '0x521f9c7505005cfa19a8e5786a9c3c9c9f5e6f42': {
      name: 'Forgotten Runes Wizards Cult',
      category: 'NFT Collection',
    },

    '0xe4cfae3aa41115cb94cff39bb5dbae8bd0ea9d41': {
      name: 'COVIDPunks',
      category: 'NFT Collection',
    },

    '0x22c36bfdcef207f9c0cc941936eff94d4246d14a': {
      name: 'Bored Ape Chemistry Club',
      category: 'NFT Collection',
    },

    '0x7ab2352b1d2e185560494d5e577f9d3c238b78c5': {
      name: 'Adam Bomb Squad',
      category: 'NFT Collection',
    },

    '0x4f8730e0b32b04beaa5757e5aea3aef970e5b613': {
      name: 'Bloot (not for Weaks)',
      category: 'NFT Collection',
    },

    '0x34b4df75a17f8b3a6eff6bba477d39d701f5e92c': {
      name: 'GEVOLs',
      category: 'NFT Collection',
    },

    '0x05a46f1e545526fb803ff974c790acea34d1f2d6': {
      name: 'The n project',
      category: 'NFT Collection',
    },

    '0xc22616e971a670e72f35570337e562c3e515fbfe': {
      name: 'Playing Arts Crypto Edition',
      category: 'NFT Collection',
    },

    '0xce25e60a89f200b1fa40f6c313047ffe386992c3': {
      name: 'dotdotdots',
      category: 'NFT Collection',
    },

    '0x392179031da3012dac321703a29e4c9fbd26316b': {
      name: 'Al Cabones',
      category: 'NFT Collection',
    },

    '0x1ca39c7f0f65b4da24b094a9afac7acf626b7f38': {
      name: 'GEN.ART Membership',
      category: 'NFT Collection',
    },

    '0x40fb1c0f6f73b9fc5a81574ff39d27e0ba06b17b': {
      name: 'The Mike Tyson NFT Collection by Cory Van Lew',
      category: 'NFT Collection',
    },

    '0xf497253c2bb7644ebb99e4d9ecc104ae7a79187a': {
      name: 'The Sevens (Official)',
      category: 'NFT Collection',
    },

    '0x8943c7bac1914c9a7aba750bf2b6b09fd21037e0': {
      name: 'Lazy Lions',
      category: 'NFT Collection',
    },

    '0xc9cb0fee73f060db66d2693d92d75c825b1afdbf': {
      name: 'First First NFTs',
      category: 'NFT Collection',
    },

    '0x8bf2f876e2dcd2cae9c3d272f325776c82da366d': {
      name: 'Extension Loot (For Adventurers)',
      category: 'NFT Collection',
    },

    '0x1b829b926a14634d36625e60165c0770c09d02b2': {
      name: 'Treeverse',
      category: 'NFT Collection',
    },

    '0x909899c5dbb5002610dd8543b6f638be56e3b17e': {
      name: 'Plasma Bears',
      category: 'NFT Collection',
    },

    '0x21850dcfe24874382b12d05c5b189f5a2acf0e5b': {
      name: 'The KILLAZ',
      category: 'NFT Collection',
    },

    '0x80f1ed6a1ac694317dc5719db099a440627d1ea7': {
      name: 'IKB cachet dee Garantie',
      category: 'NFT Collection',
    },

    '0x059edd72cd353df5106d2b9cc5ab83a52287ac3a': {
      name: 'Art Blocks (Old)',
      category: 'NFT Collection',
    },

    '0xd754937672300ae6708a51229112de4017810934': {
      name: 'Deafbeef',
      category: 'NFT Collection',
    },

    '0xc170384371494b2a8f6ba20f4d085c4dde763d96': {
      name: 'beeple: {EVERYDAYS - THE 2020 COLLECTION',
      category: 'NFT Collection',
    },

    '0xe573b99ffd4df2a82ea0986870c33af4cb8a5589': {
      name: 'Chain/Saw',
      category: 'NFT Collection',
    },

    '0xba30e5f9bb24caa003e9f2f0497ad287fdf95623': {
      name: 'Bored ape Kennel Club',
      category: 'NFT Collection',
    },

    '0x3c62e8de798721963b439868d3ce22a5252a7e03': {
      name: 'F1 Delta Time',
      category: 'NFT Collection',
    },

    '0x9a534628b4062e123ce7ee2222ec20b86e16ca8f': {
      name: 'MekaVerse',
      category: 'NFT Collection',
    },

    '0x12d2d1bed91c24f878f37e66bd829ce7197e4d14': {
      name: 'GalacticApes',
      category: 'NFT Collection',
    },

    '0xdd69da9a83cedc730bc4d3c56e96d29acc05ecde': {
      name: 'timepieces build a better future: {Genesis Drop',
      category: 'NFT Collection',
    },

    '0x3a5051566b2241285be871f650c445a88a970edd': {
      name: 'The Humanoids',
      category: 'NFT Collection',
    },

    '0xc8bcbe0e8ae36d8f9238cd320ef6de88784b1734': {
      name: 'Winter Bears',
      category: 'NFT Collection',
    },

    '0x6dc6001535e15b9def7b0f6a20a2111dfa9454e2': {
      name: 'metahero universe: {Generative Identities',
      category: 'NFT Collection',
    },

    '0x8a1bbef259b00ced668a8c69e50d92619c672176': {
      name: 'Habbo Avatars',
      category: 'NFT Collection',
    },

    '0x90ca8a3eb2574f937f514749ce619fdcca187d45': {
      name: 'Gambling Apes Official',
      category: 'NFT Collection',
    },

    '0x1d20a51f088492a0f1c57f047a9e30c9ab5c07ea': {
      name: 'loomlocknft (Wassies)',
      category: 'NFT Collection',
    },

    '0xaadba140ae5e4c8a9ef0cc86ea3124b446e3e46a': {
      name: 'MutantCats',
      category: 'NFT Collection',
    },

    '0x4b3406a41399c7fd2ba65cbc93697ad9e7ea61e5': {
      name: 'lostpoets: {POETS',
      category: 'NFT Collection',
    },

    '0x236672ed575e1e479b8e101aeeb920f32361f6f9': {
      name: 'Frontier Game',
      category: 'NFT Collection',
    },

    '0x86357a19e5537a8fba9a004e555713bc943a66c0': {
      name: 'Neo Tokio Identities',
      category: 'NFT Collection',
    },

    '0x9c57d0278199c931cf149cc769f37bb7847091e7': {
      name: 'Sipheria Surge',
      category: 'NFT Collection',
    },

    '0x9261b6239a85348e066867c366d3942648e24511': {
      name: 'Monkey Bet DAO',
      category: 'NFT Collection',
    },

    '0xfcb1315c4273954f74cb16d5b663dbf479eec62e': {
      name: 'Capsule House',
      category: 'NFT Collection',
    },

    '0x454cbc099079dc38b145e37e982e524af3279c44': {
      name: 'The Yakuza Cats Society',
      category: 'NFT Collection',
    },

    '0x9d418c2cae665d877f909a725402ebd3a0742844': {
      name: 'Fang Gang',
      category: 'NFT Collection',
    },

    '0x1897d69cc0088d89c1e94889fbd2efffcefed778': {
      name: 'Gutter Spiecies Mint Pass',
      category: 'NFT Collection',
    },

    '0xa406489360a47af2c74fc1004316a64e469646a5': {
      name: 'The Official Surreals',
      category: 'NFT Collection',
    },

    '0x6317c4cab501655d7b85128a5868e98a094c0082': {
      name: 'MonsterBuds',
      category: 'NFT Collection',
    },

    '0xce50f3ca1f1dbd6fa042666bc0e369565dda457d': {
      name: 'TheHeartProject',
      category: 'NFT Collection',
    },

    '0x364c828ee171616a39897688a831c2499ad972ec': {
      name: 'Sappy Seals',
      category: 'NFT Collection',
    },

    '0x2d3f39663d43c0862589a8a24bf05ccd44b0ac4d': {
      name: 'StackedToadz',
      category: 'NFT Collection',
    },

    '0x0bb3e1c80c0ffde985fb0ec6392aa18a1d2de40e': {
      name: 'Fatales',
      category: 'NFT Collection',
    },

    '0x74ecb5f64363bd663abd3ef08df75dd22d853bfc': {
      name: 'Gauntlets',
      category: 'NFT Collection',
    },

    '0xc6735852e181a55f736e9db62831dc63ef8c449a': {
      name: 'Rogue Society Bots',
      category: 'NFT Collection',
    },

    '0x8707276df042e89669d69a177d3da7dc78bd8723': {
      name: 'Dope Wars',
      category: 'NFT Collection',
    },

    '0xccee4d557588e982ecf3a2391d718c76589d8af9': {
      name: 'CryptoZoo.co',
      category: 'NFT Collection',
    },

    '0x1981cc36b59cffdd24b01cc5d698daa75e367e04': {
      name: 'Crypto.Chicks',
      category: 'NFT Collection',
    },

    '0xaa6612f03443517ced2bdcf27958c22353ceeab9': {
      name: 'Bamboozlers',
      category: 'NFT Collection',
    },

    '0x1710d860034b50177d793e16945b6a25c7d92476': {
      name: 'ZombieToadz',
      category: 'NFT Collection',
    },

    '0xe885d519025348e30174a218be3e3e4754222860': {
      name: 'Woodies Mint Passport',
      category: 'NFT Collection',
    },

    '0x08f0b2a4351514e63e9e03a661adfe58d463cfbc': {
      name: 'The Project URS',
      category: 'NFT Collection',
    },

    '0x15a2d6c2b4b9903c27f50cb8b32160ab17f186e2': {
      name: 'GOOP TROOP',
      category: 'NFT Collection',
    },

    '0x960b7a6bcd451c9968473f7bbfd9be826efd549a': {
      name: 'OnChainMonkey',
      category: 'NFT Collection',
    },

    '0xdd467a6c8ae2b39825a452e06b4fa82f73d4253d': {
      name: 'MarsCatsVoyage',
      category: 'NFT Collection',
    },

    '0x7cba74d0b16c8e18a9e48d3b7404d7739bb24f23': {
      name: 'FoxFam',
      category: 'NFT Collection',
    },

    '0xb5c747561a185a146f83cfff25bdfd2455b31ff4': {
      name: 'Boss Beauties',
      category: 'NFT Collection',
    },

    '0x986aea67c7d6a15036e18678065eb663fc5be883': {
      name: 'Nifty League DEGENs',
      category: 'NFT Collection',
    },

    '0xbd4455da5929d5639ee098abfaa3241e9ae111af': {
      name: 'NFT Worlds (nftworlds.com)',
      category: 'NFT Collection',
    },

    '0x36d02dcd463dfd71e4a07d8aa946742da94e8d3a': {
      name: 'SympathyForTheDevils',
      category: 'NFT Collection',
    },

    '0xf3ae416615a4b7c0920ca32c2dfebf73d9d61514': {
      name: 'Chiptos',
      category: 'NFT Collection',
    },

    '0x0b35406f0215422ae1fd33560f11e92799150ad6': {
      name: 'Wicked Monsters',
      category: 'NFT Collection',
    },

    '0xce82d65314502ce39472a2442d4a2cbc4cb9f293': {
      name: 'Animal Society',
      category: 'NFT Collection',
    },

    '0x1a2f71468f656e97c2f86541e57189f59951efe7': {
      name: 'CryptoMories',
      category: 'NFT Collection',
    },

    '0xa64c5ec80784675bf289a4722a2eca180212f9db': {
      name: 'Wicked Hounds',
      category: 'NFT Collection',
    },

    '0x4621f7789179808114c5685fd5e2847a0f7b2246': {
      name: 'Penguin Fight Club',
      category: 'NFT Collection',
    },

    '0xfaff15c6cdaca61a4f87d329689293e07c98f578': {
      name: 'Zapper NFT',
      category: 'NFT Collection',
    },

    '0x1c645e4075f225ec82ed7fc4d81ff935c6fa00ae': {
      name: 'LevX DAO Collection',
      category: 'NFT Collection',
    },

    '0xa3b7cee4e082183e69a03fc03476f28b12c545a7': {
      name: 'Chill Frogs',
      category: 'NFT Collection',
    },

    '0x49cf6f5d44e70224e2e23fdcdd2c053f30ada28b': {
      name: 'Clone X - X TAKASHI MURAKAMI',
      category: 'NFT Collection',
    },

    '0x8a90cab2b38dba80c64b7734e58ee1db38b8992e': {
      name: 'Doodles',
      category: 'NFT Collection',
    },

    '0x348fc118bcc65a92dc033a951af153d14d945312': {
      name: 'RTFKT - CloneX Mintvial',
      category: 'NFT Collection',
    },

    '0x7e6bc952d4b4bd814853301bee48e99891424de0': {
      name: 'Jungle Freaks',
      category: 'NFT Collection',
    },

    '0x0c2e57efddba8c768147d1fdf9176a0a6ebd5d83': {
      name: 'KaijuKingz',
      category: 'NFT Collection',
    },

    '0xeb834ae72b30866af20a6ce5440fa598bfad3a42': {
      name: 'Wolf Game',
      category: 'NFT Collection',
    },

    '0x28472a58a490c5e09a238847f66a68a47cc76f0f': {
      name: 'adidas originals: {Into the Metaverse',
      category: 'NFT Collection',
    },

    '0x999e88075692bcee3dbc07e7e64cd32f39a1d3ab': {
      name: 'Wizards & Dragons Game',
      category: 'NFT Collection',
    },

    '0x11450058d796b02eb53e65374be59cff65d3fe7f': {
      name: 'SHIBOSHIS',
      category: 'NFT Collection',
    },

    '0xf1268733c6fb05ef6be9cf23d24436dcd6e0b35e': {
      name: 'Desperate ApeWives',
      category: 'NFT Collection',
    },

    '0x9bf252f97891b907f002f2887eff9246e3054080': {
      name: 'Ape Kids Club',
      category: 'NFT Collection',
    },

    '0x97597002980134bea46250aa0510c9b90d87a587': {
      name: 'Chain Runners',
      category: 'NFT Collection',
    },

    '0xab0b0dd7e4eab0f9e31a539074a03f1c1be80879': {
      name: 'neo tokyo part 2: {Valut Cards',
      category: 'NFT Collection',
    },

    '0x698fbaaca64944376e2cdc4cad86eaa91362cf54': {
      name: 'neo tokyo: {Outer Identities',
      category: 'NFT Collection',
    },

    '0xf7143ba42d40eaeb49b88dac0067e54af042e963': {
      name: 'Metasaurs',
      category: 'NFT Collection',
    },

    '0x469823c7b84264d1bafbcd6010e9cdf1cac305a3': {
      name: 'Crypto Bull Society',
      category: 'NFT Collection',
    },

    '0xf75140376d246d8b1e5b8a48e3f00772468b3c0c': {
      name: 'uwucrew',
      category: 'NFT Collection',
    },

    '0xed5af388653567af2f388e6224dc7c4b3241c544': {
      name: 'Azuki',
      category: 'NFT Collection',
    },

    '0xfb3765e0e7ac73e736566af913fa58c3cfd686b7': {
      name: 'Audioglyphs',
      category: 'NFT Collection',
    },

    '0x67d9417c9c3c250f61a83c7e8658dac487b56b09': {
      name: 'PhantaBear',
      category: 'NFT Collection',
    },

    '0x6632a9d63e142f17a668064d41a21193b49b41a0': {
      name: 'Prime Ape Planet',
      category: 'NFT Collection',
    },

    '0x4db1f25d3d98600140dfc18deb7515be5bd293af': {
      name: 'HAPE PRIME',
      category: 'NFT Collection',
    },

    '0x7f36182dee28c45de6072a34d29855bae76dbe2f': {
      name: 'Wolf Game',
      category: 'NFT Collection',
    },

    '0xfe8c6d19365453d26af321d0e8c910428c23873f': {
      name: 'Cold Blooded Creepz',
      category: 'NFT Collection',
    },

    '0xd78b76fcc33cd416da9d3d42f72649a23d7ac647': {
      name: 'Lil` Heroes by Edgar Plans',
      category: 'NFT Collection',
    },

    '0x6be69b2a9b153737887cfcdca7781ed1511c7e36': {
      name: 'Killer GF',
      category: 'NFT Collection',
    },

    '0xc8adfb4d437357d0a656d4e62fd9a6d22e401aa0': {
      name: 'CryptoBatz by Ozzy Osborne',
      category: 'NFT Collection',
    },

    '0x6fd053bff10512d743fa36c859e49351a4920df6': {
      name: 'C-01 Official Collection',
      category: 'NFT Collection',
    },

    '0x75e95ba5997eb235f40ecf8347cdb11f18ff640b': {
      name: 'Psychedelics Anonymous Genesis',
      category: 'NFT Collection',
    },

    '0x0e9d6552b85be180d941f1ca73ae3e318d2d4f1f': {
      name: 'Metaverse City Block',
      category: 'NFT Collection',
    },

    '0xe106c63e655df0e300b78336af587f300cff9e76': {
      name: 'OxyaOriginProject',
      category: 'NFT Collection',
    },

    '0xa5c0bd78d1667c13bfb403e2a3336871396713c5': {
      name: 'Coolman`s Universe',
      category: 'NFT Collection',
    },

    '0x0b4b2ba334f476c8f41bfe52a428d6891755554d': {
      name: 'JRNY CLUB OFFICIAL',
      category: 'NFT Collection',
    },

    '0x09233d553058c2f42ba751c87816a8e9fae7ef10': {
      name: 'My Pet Hooligan',
      category: 'NFT Collection',
    },

    '0xa1d4657e0e6507d5a94d06da93e94dc7c8c44b51': {
      name: 'Worldwide Webb Land',
      category: 'NFT Collection',
    },

    '0xf1083e064f92db0561fd540f982cbf73a4e2f8f6': {
      name: 'Creepz Shapeshifters',
      category: 'NFT Collection',
    },

    '0xd532b88607b1877fe20c181cba2550e3bbd6b31c': {
      name: 'Zipcy`s SuperNormal',
      category: 'NFT Collection',
    },

    '0x94638cbf3c54c1f956a5f05cbc0f9afb6822020d': {
      name: 'inBetweeners',
      category: 'NFT Collection',
    },

    '0x9372b371196751dd2f603729ae8d8014bbeb07f6': {
      name: 'Bored Bunny',
      category: 'NFT Collection',
    },

    '0x4e1f41613c9084fdb9e34e11fae9412427480e56': {
      name: 'Terraforms',
      category: 'NFT Collection',
    },

    '0x68b3465833fb72a70ecdf485e0e4c7bd8665fc45': {
      name: 'Uniswap V3: Router 2',
      category: 'DeFi',
    },

    '0x00000000006c3852cbef3e08e8df289169ede581': {
      name: 'Seaport',
      category: 'NFT Marketplace',
    },

    '0xdac17f958d2ee523a2206206994597c13d831ec7': {
      name: 'Tether',
      category: 'Token',
    },

    '0x7a250d5630b4cf539739df2c5dacb4c659f2488d': {
      name: 'Uniswap V2: Router 2',
      category: 'DeFi',
    },

    '0x1111111254fb6c44bac0bed2854e76f90643097d': {
      name: '1inch v4: Router',
      category: 'DeFi',
    },

    '0x881d40237659c251811cec9c364ef91dc08d300c': {
      name: 'Metamask: Swap Router',
      category: 'DeFi',
    },

    '0x283af0b28c62c092c9727f1ee09c02ca627eb7f5': {
      name: 'ENS: Eth Registrar Controller',
      category: 'NFT',
    },

    '0x83c8f28c26bf6aaca652df1dbbe0e1b56f8baba2': {
      name: 'Gem: GemSwap 2',
      category: 'NFT',
    },

    '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48': {
      name: 'Centre: USD Coin',
      category: 'Token',
    },

    '0xdef1c0ded9bec7f1a1670819833240f027b25eff': {
      name: '0x: Exchange Prox',
      category: 'DeFi',
    },

    '0x4c6f947ae67f572afa4ae0730947de7c874f95ef': {
      name: 'Arbitrum Sequencer Inbox',
      category: 'bridge',
    },

    '0xeeca143947ae66d5f74acb0dbb105faf47b29596': {
      name: 'SeriesTwo',
      category: 'NFT',
    },

    '0xa090e606e30bd747d4e6245a1517ebe430f0057e': {
      name: 'Coinbase: Miscellaneous',
      category: 'DeFi',
    },

    '0x903e2f16bc81d0a3ba9aaf649cfe13f901b6a620': {
      name: 'Goblin Cats',
      category: 'NFT',
    },

    '0xc36442b4a4522e871399cd717abdd847ab11fe88': {
      name: 'Uniswap V3: Positions NFT',
      category: 'NFT',
    },

    '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2': {
      name: 'Wrapped Ether',
      category: 'Token',
    },

    '0x7f463b874ec264dc7bd8c780f5790b4fc371f11f': {
      name: 'BlonkSmain',
      category: 'NFT',
    },

    '0x98c3d3183c4b8a650614ad179a1a98be0a8d6b8e': {
      name: 'MEV Bot: 0x98C3d3183C4b8A650614ad179A1a98be0a8d6B8E',
      category: 'MEV',
    },

    '0xdef171fe48cf0115b1d80b88dc8eab59176fee57': {
      name: 'Paraswap v5: Augustus Swapper eth Mainnet',
      category: 'DeFi',
    },

    '0xa57bd00134b2850b2a1c55860c9e9ea100fdd6cf': {
      name: 'MEV BOT: 0xa57Bd00134B2850B2a1c55860c9e9ea100fDd6CF',
      category: 'MEV',
    },

    '0xe66b31678d6c16e9ebf358268a790b763c133750': {
      name: '0x: Coinbase Wallet Proxy',
      category: 'DeFi',
    },

    '0x5e4e65926ba27467555eb562121fac00d24e9dd2': {
      name: 'Optimism: Canonical Transaction Chain',
      category: 'Bridge',
    },

    '0xbeefbabeea323f07c59926295205d3b7a17e8638': {
      name: 'MEV Bot: 0xBEEFBaBEeA323F07c59926295205d3b7a17E8638',
      category: 'MEV',
    },

    '0x881d4032abe4188e2237efcd27ab435e81fc6bb1': {
      name: 'Coinbase: Commerce',
      category: 'DeFi',
    },

    '0x0000000000c2d145a2526bd8c716263bfebe1a72': {
      name: 'TransferHelper',
      category: 'DeFi',
    },

    '0xb16c1342e617a5b6e4b631eb114483fdb289c0a4': {
      name: 'sudoswap: Pair Factory',
      category: 'DeFi',
    },

    '0xfc4913214444af5c715cc9f7b52655e788a569ed': {
      name: 'Icosa',
      category: 'Token',
    },

    '0xcda72070e455bb31c7690a170224ce43623d0b6f': {
      name: 'Foundation: Market',
      category: 'NFT Marketplace',
    },

    '0x4d05e3d48a938db4b7a9a59a802d5b45011bde58': {
      name: 'RocketDepositPool',
      category: 'DeFi',
    },

    '0xfbddadd80fe7bda00b901fbaf73803f2238ae655': {
      name: 'StrongBlock: Service',
      category: 'Infrastructure',
    },
    '0x23581767a106ae21c074b2276D25e5C3e136a68b': {
      name: 'Moonbirds: MOONBIRD Token',
      category: 'NFT Collection',
    },
    '0x1792a96e5668ad7c167ab804a100ce42395ce54d': {
      name: 'Moonbirds Oddities',
      category: 'NFT Collection',
    },
    '0xcCA8050215E585E2a223C6eA9D1D1F9b30BEAf3e': {
      name: 'CryptoSimeji',
      category: 'NFT Collection',
    },
    '0xd1258DB6Ac08eB0e625B75b371C023dA478E94A9': {
      name: 'DigiDaigaku',
      category: 'NFT Collection',
    },
    '0x08D7C0242953446436F34b4C78Fe9da38c73668d': {
      name: 'PROOF Collective',
      category: 'NFT Collection',
    },
    '0x34d85c9CDeB23FA97cb08333b511ac86E1C4E258': {
      name: 'Otherdeed',
      category: 'NFT Collection',
    },
    '0x8DcB8B2d721c022552d826F8bcf2995747248d31': {
      name: 'Women Ape Yacht Club (WAYC)',
      category: 'NFT Collection',
    },
    '0x64Ad353BC90A04361c4810Ae7b3701f3bEb48D7e': {
      name: 'Black Box',
      category: 'NFT Collection',
    },
    '0x2f46E37477Ca4033D74986b15F0572e9913B4858': {
      name: 'Proof of Pepe',
      category: 'NFT Collection',
    },
    '0x658bdf5cba855E04724589a61c3E4B1856B69A5B': {
      name: 'POW',
      category: 'NFT Collection',
    },
    '0x3Cf69C6e77B1cF77f6BD41f4497aDa96646F66a1': {
      name: 'Art Party',
      category: 'NFT Collection',
    },
    '0x569718D9E7562312cEBf9316C31d000C80D542e3': {
      name: 'Mihaverse Butterfly',
      category: 'NFT Collection',
    },
    '0x31d45de84fdE2fB36575085e05754a4932DD5170': {
      name: 'RareApepeYachtClub',
      category: 'NFT Collection',
    },
    '0x6AF88A2B51D37DD80B59CdA3aaac55eE34C6ea07': {
      name: 'Raft',
      category: 'NFT Collection',
    },
    '0x39ee2c7b3cb80254225884ca001F57118C8f21B6': {
      name: 'Potatoz',
      category: 'NFT Collection',
    },
    '0x6661c87764AdF7FffA3c7922FA6EDFa2fd62cCfC': {
      name: 'Commoners by Commonopoly',
      category: 'NFT Collection',
    },
    '0x29D7Ebca656665C1A52a92F830E413E394db6b4F': {
      name: 'Phoenixes',
      category: 'NFT Collection',
    },
    '0x16de9D750F4AC24226154C40980Ef83d4D3fD4AD': {
      name: 'Ethlizards',
      category: 'NFT Collection',
    },
    '0xCCF3BAA603dfddd7c41619FDB8dd0306B11571Fe': {
      name: 'YOLO Bunny',
      category: 'NFT Collection',
    },
    '0x7DaEC605E9e2a1717326eeDFd660601e2753A057': {
      name: '10KTF Stockroom',
      category: 'NFT Collection',
    },
    '0x543D43F390b7d681513045e8a85707438c463d80': {
      name: 'Webaverse Genesis Pass',
      category: 'NFT Collection',
    },
    '0x5a0121A0A21232eC0D024dAb9017314509026480': {
      name: 'Finiliar',
      category: 'NFT Collection',
    },
    '0xbCe3781ae7Ca1a5e050Bd9C4c77369867eBc307e': {
      name: 'goblintown',
      category: 'NFT Collection',
    },
    '0x306b1ea3ecdf94aB739F1910bbda052Ed4A9f949': {
      name: 'Beanz',
      category: 'NFT Collection',
    },
    '0x3598Fff0f78Dd8b497e12a3aD91FeBcFC8F49d9E': {
      name: 'Private Jet Pyjama Party',
      category: 'NFT Collection',
    },
    '0x0599699BBFC3a92589AD249607f7265c08A1FB61': {
      name: 'Oz DAO Pass',
      category: 'NFT Collection',
    },
    '0x36F4D96Fe0D4Eb33cdC2dC6C0bCA15b9Cdd0d648': {
      name: 'gmDAO Token v2',
      category: 'NFT Collection',
    },
    '0x63d85ec7B1561818Ec03E158ec125a4113038A00': {
      name: 'Kryptoria: Alpha Citizens',
      category: 'NFT Collection',
    },
    '0x2a48420D75777aF4c99970C0ED3C25effD1C08be': {
      name: 'MV3NFT',
      category: 'NFT Collection',
    },
    '0x39223e2596bF8E1dE3894f66947cacc614C24A2f': {
      name: 'Suki Learns to Fly',
      category: 'NFT Collection',
    },
    '0xd1A4221358DBbCFB0eb575258eCF8972A700613d': {
      name: 'Tribe Diamonds',
      category: 'NFT Collection',
    },
    '0xC50b06f4444c5A795f1246d9416aDffc178033fb': {
      name: 'Ugly Duck WTF',
      category: 'NFT Collection',
    },
    '0xf2411B38596DEb99B8D3F15E7333091aDd537b75': {
      name: 'Y00ts ETH',
      category: 'NFT Collection',
    },
    '0xdA74Aeb14546b4272036121AeD3A97515c84F317': {
      name: 'Private Jet Pyjama Party First Ladies',
      category: 'NFT Collection',
    },
    '0xE5170bE552E725436365BeDE40868A26184F291D': {
      name: 'My Cyborg Son',
      category: 'NFT Collection',
    },
    '0x008C69E0c66EbD4b228D27F2162aD54AB1b7dDE1': {
      name: 'BIG3 Ownership',
      category: 'NFT Collection',
    },
    '0xe17827609Ac34443B3987661f4e037642F6BD9bA': {
      name: 'Shellz Orb',
      category: 'NFT Collection',
    },
    '0xbcE6D2aa86934AF4317AB8615F89E3F9430914Cb': {
      name: 'VaynerSports Pass',
      category: 'NFT Collection',
    },
    '0xef1c6e67703c7bd7107eed8303fbe6ec2554bf6b': {
      name: 'Uniswap: Universal Router',
      category: 'Defi',
    },
    '0x7d2768de32b0b80b7a3454c06bdac94a69ddc7a9': {
      name: 'Aave V2',
      category: 'Defi',
    },
    '0xa0c68c638235ee32657e8f720a23cec1bfc77c77': {
      name: 'Polygon Bridge',
      category: 'Defi',
    },
    '0x0000000000000000000000000000000000000000': {
      name: 'Null Address',
      category: '',
    },
    '0x000000000000000000000000000000000000dEaD': {
      name: 'Burn Address',
      category: '',
    },
    '0xe8b6395d223c9d3d85e162f2cb2023bc9088a908': {
      name: 'Your Phi Land',
    },
  },
  '0x89': {
    '0xbeb09beb09e95e6febf0d6eeb1d0d46d1013cc3c': {
      name: 'Bebop Router',
    },
    '0x0000000000000000000000000000000000000000': {
      name: 'Null Address',
    },
  },
};
