import { buildBannerMessage } from '../../../../helpers/engines/transactionSummaryEngine';
import {
  DeepSimulationType,
  philandDeepSimulationType,
} from '../../../../helpers/enums/application.enums';
import { SimulationData } from '../../../../helpers/interfaces/dataTypes.interface';
import {
  contractBannerColor,
  contractTextColor,
} from '../../../../helpers/methods';
import DomainCard from '../../../Banner/DomainCard';
import { PhiLogo } from './PhiLogo';

interface IPhilandBannerProps {
  simulationType: DeepSimulationType;
  transactionSimulation: SimulationData | undefined;
  sourceUrl: string | undefined;
  network: string;
  transactionType: string;
}

const simulationTypeToActionMapping = (simulationType: DeepSimulationType) => {
  switch (simulationType) {
    case DeepSimulationType.PHILAND_LAND_DEPOSIT:
      return 'Deposit';
    case DeepSimulationType.PHILAND_LAND_CLAIM:
      return 'Claim';
    case DeepSimulationType.PHILAND_LAND_WITHDRAW:
      return 'Withdraw';
    case DeepSimulationType.PHILAND_LAND_CREATE:
      return 'Create Land';
    case DeepSimulationType.PHILAND_LAND_SAVE:
      return 'Save Land';
    case DeepSimulationType.PHILAND_LAND_BUY:
      return 'Buy';
    case DeepSimulationType.PHILAND_LAND_BUY_AND_DEPOSIT:
      return 'Buy And Deposit';
    default:
      throw new Error("Invalid simulation type for Philand's banner");
  }
};

const PhilandBanner: React.FC<IPhilandBannerProps> = ({
  simulationType,
  transactionSimulation,
  network,
  sourceUrl,
  transactionType,
}) => {
  if (!philandDeepSimulationType.includes(simulationType)) {
    return <></>;
  }
  const action = simulationTypeToActionMapping(simulationType);
  const isSignature = !!transactionSimulation?.signature;
  const isApproval = transactionType.includes('Approve');
  const isRevokal =
    isApproval &&
    ['0', 'none'].includes(
      `${transactionSimulation?.netBalanceChanges[0]?.value}`
    );

  //show warning banner for signatures and approvals
  const displayWarningBanner = isSignature || (isApproval && !isRevokal);
  const backgroundColor = contractBannerColor(displayWarningBanner);
  const color = contractTextColor(displayWarningBanner);

  const message = transactionSimulation ? (
    buildBannerMessage(
      transactionType,
      transactionSimulation,
      displayWarningBanner,
      network
    ).map((element: any, i: number) => {
      return (
        <span
          key={i}
          className={`${element.bold ? 'font-bold' : 'font-light'} ${
            element.text.includes('#') && 'break-words'
          }`}
        >
          {element.text}
        </span>
      );
    })
  ) : (
    <></>
  );

  return (
    <div className={`min-h-36 ${backgroundColor} p-4`}>
      <div
        className={`text-xl ${color} flex flex-row justify-center items-center font-ClashDisplay font-medium`}
      >
        {PhiLogo}
        <div className="tracking-wide">{action}</div>
      </div>
      <div
        className={`${color} dark:${color} mt-0 mb-0 font-Manrope text-center`}
      >
        {message}
      </div>
      {sourceUrl && <DomainCard sourceUrl={sourceUrl} />}
    </div>
  );
};

export default PhilandBanner;
