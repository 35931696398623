import { getBlockExplorerUrl } from '../../helpers/methods';
import { useAddressLabels } from '../../hooks/useAddressLabel';
import Address from '../Atoms/Address';
import CurrencyLogo from '../Atoms/CurrencyLogo';
import LinkoutButton from '../Atoms/LinkoutButton';
import TransactionRowContainer from '../Containers/TransactionRowContainer';
import { PhishingIcon } from '../../helpers/icons/icons';

export interface ISpenderCardProps {
  customIcon?: JSX.Element | undefined;
  network: string;
  logo: string | undefined;
  spender: string;
  hideLinkout?: boolean;
}

const SpenderCard: React.FC<ISpenderCardProps> = ({
  customIcon,
  network,
  logo,
  spender,
  hideLinkout = false,
}) => {
  const {
    data: addressLabelMap,
    loading,
    logos,
  } = useAddressLabels([spender], network, undefined, 1);

  const spenderLabel = addressLabelMap
    ?.get(spender)
    ?.startsWith('Fake_Phishing')
    ? 'ALERT - Malicious Target'
    : addressLabelMap?.get(spender);

  const phishingIcon = spenderLabel?.startsWith('ALERT')
    ? PhishingIcon
    : undefined;

  const retrievedLogo = logo && logo.length ? logo : logos?.get(spender) ?? '';

  return (
    <TransactionRowContainer loading={loading}>
      <div className="interacting-item-label">
        <div className="interacting-item-token">
          {phishingIcon ? (
            phishingIcon
          ) : customIcon ? (
            customIcon
          ) : retrievedLogo.length ? (
            <img
              className="h-[32px] w-[32px] rounded-full"
              src={retrievedLogo}
              alt=""
            />
          ) : (
            <CurrencyLogo
              className="h-[32px] w-[32px] rounded-full"
              type="revokal"
              searchType="name"
              identifier={spenderLabel}
            />
          )}
        </div>
        <div>
          <Address
            className="ml-1 overflow-hidden whitespace-nowrap text-overflow-ellipsis font-ClashDisplay text-xl font-medium"
            overrideClassDefaults={true}
            labeledAddress={spenderLabel}
            address={spender}
            network={network}
          />
        </div>
        {!hideLinkout && (
          <LinkoutButton
            className="mr-4"
            onClick={() => window.open(getBlockExplorerUrl(spender, network))}
          />
        )}
      </div>
    </TransactionRowContainer>
  );
};

export default SpenderCard;
