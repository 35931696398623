import {
  buildERC20TransferProps,
  buildNftTransferProps,
  buildERC20ApproveProps,
  buildApprovalProps,
} from '../../../helpers/engines/PropEngine';
import Approval from '../Approvals/Approval';
import ERC20Transfer from '../Transfers/ERC20Transfer';
import NftTransfer from '../Transfers/NftTransfer';

export type ComponentMap = Map<
  string,
  {
    component: React.ComponentType<any>;
    propsConstructor?: (...args: any[]) => any;
  }
>;

export const componentMap: ComponentMap = new Map([
  [
    'erc20',
    {
      component: ERC20Transfer,
      propsConstructor: buildERC20TransferProps,
    },
  ],
  [
    'erc1155',
    {
      component: NftTransfer,
      propsConstructor: buildNftTransferProps,
    },
  ],
  [
    'erc721',
    {
      component: NftTransfer,
      propsConstructor: buildNftTransferProps,
    },
  ],
  [
    'erc20Approve',
    {
      component: Approval,
      propsConstructor: buildERC20ApproveProps,
    },
  ],
  [
    'erc721Approve',
    {
      component: Approval,
      propsConstructor: buildApprovalProps,
    },
  ],
  [
    'erc1155Approve',
    {
      component: Approval,
      propsConstructor: buildApprovalProps,
    },
  ],
  [
    'eth',
    {
      component: ERC20Transfer,
      propsConstructor: buildERC20TransferProps,
    },
  ],
]);
