import TransactionDivider from '../Atoms/TransactionDivider';
import SignatureCard from '../Signature/SignatureCard';

interface ICustomSignatureContentProps {
  messageHeader: string;
  message: string;
  counterpartyAction: string;
  sourceUrl: string | undefined;
  logo: string;
}
const CustomSignatureContent: React.FC<ICustomSignatureContentProps> = ({
  ...props
}) => {
  return (
    <div className="mb-6 mt-5">
      <div className="font-ClashDisplay text-lg font-medium ml-10 mb-1">
        {props.messageHeader}
      </div>
      <div className="font-Manrope mb-3 whitespace-pre-wrap w-[315px] min-h-[134px] h-fit max-h-[238px] rounded-xl mx-auto bg-primary-400 text-secondary-800 overflow-y-auto p-3 overflow-x-hidden break-words text-normal">
        {props.message}
      </div>
      <TransactionDivider />
      <div>
        <div className="font-ClashDisplay text-lg font-medium ml-10 mb-2">
          {props.counterpartyAction}
        </div>
        <SignatureCard
          sourceUrl={props.sourceUrl ?? 'Unknown'}
          logo={props.logo}
        />
      </div>
    </div>
  );
};

export default CustomSignatureContent;
