import { useContext } from 'react';
import { SimulationContext } from '../../../contexts/SimulationContext';
import { SplitNetBalanceChanges } from '../../../helpers/methods';
import TransactionDivider from '../../Atoms/TransactionDivider';
import SpenderCard from '../../Shared/SpenderCard';
import Empty from '../Transfers/Empty';
//import GasEstimate from '../Transfers/GasEstimate';
import TransactionFactory from './TransactionFactory';

interface ITransactionsProps {
  netBalanceChanges: any | undefined;
  hexChainId: string;
  userAddress: string;
  transactionType: string;
}

const Transactions: React.FC<ITransactionsProps> = ({
  netBalanceChanges,
  hexChainId,
  userAddress,
  transactionType,
}) => {
  const { eipGlobalInformation, transactionSimulation } =
    useContext(SimulationContext);

  const { incomingTxs, outgoingTxs /*, validators*/ } = SplitNetBalanceChanges(
    netBalanceChanges,
    userAddress
  );

  ////console.log('TXNs', { incomingTxs, outgoingTxs });
  //build the incoming and outgoing transaction components
  const outgoing = TransactionFactory(outgoingTxs);
  const incoming = TransactionFactory(incomingTxs);

  //Label display logic for approvals/revokals
  const isApproval = transactionType.includes('Approve');
  const isRevoke = ['0', 'none'].includes(netBalanceChanges[0].value);
  const outgoingLabel = isApproval ? (
    <>
      You are <b>Approving</b>
    </>
  ) : (
    <>
      You are <b>Sending</b>
    </>
  );
  const incomingLabel =
    isApproval && isRevoke ? (
      'Revoking From'
    ) : isApproval ? (
      'Approval To'
    ) : (
      <>
        You are <b>Receiving</b>
      </>
    );

  //Show the divider in cases where there are assets going in and out
  //This excludes 1-sided signatures since there could be multiple counterparties on the other side of the tx
  const showDivider =
    !!incomingTxs.length ||
    (!!outgoingTxs.length &&
      !(
        incomingTxs.length > 1 ||
        (outgoingTxs.length > 1 && transactionSimulation?.signature)
      ));

  return (
    <>
      {/* If no balance changes and just gas show the empty view */}
      {!incomingTxs.length && !outgoingTxs.length && <Empty />}
      {/* If outgoing txs > 1, show the regular view */}
      {!!outgoingTxs.length && (
        <>
          <div
            className={`interacting-row ${
              isApproval ? 'approving-content' : 'receiving-content'
            }`}
          >
            <div className="interacting-row-header flex align-center justify-space-between">
              <div className="transaction-type">
                {isApproval ? (
                  //V sign
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_6819_3848)">
                      <path
                        d="M5.00016 8.00004L7.00016 10L11.0002 6.00004M14.6668 8.00004C14.6668 11.6819 11.6821 14.6667 8.00016 14.6667C4.31826 14.6667 1.3335 11.6819 1.3335 8.00004C1.3335 4.31814 4.31826 1.33337 8.00016 1.33337C11.6821 1.33337 14.6668 4.31814 14.6668 8.00004Z"
                        stroke="#356AF3"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6819_3848">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                ) : (
                  //left red arrow (outgoing)
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_6938_3175)">
                      <path
                        d="M2.22542 4.66659C3.37812 2.67391 5.5326 1.33325 8.00021 1.33325C11.6821 1.33325 14.6669 4.31802 14.6669 7.99992C14.6669 11.6818 11.6821 14.6666 8.00021 14.6666C5.5326 14.6666 3.37812 13.3259 2.22542 11.3333M8.00016 10.6666L10.6668 7.99992M10.6668 7.99992L8.00016 5.33325M10.6668 7.99992H1.3335"
                        stroke="#FF4D4D"
                        strokeWidth="1.33333"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6938_3175">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )}
                <span>{outgoingLabel}</span>
              </div>
              {/* <div>
                {isApproval && !!validators.length && (
                  <GasEstimate
                    hexChainId={eipGlobalInformation.network}
                    usdPrice={validators[0]?.usdValue ?? -1}
                    value={validators[0]?.value}
                    decimals={validators[0]?.decimals}
                  />
                )}
              </div> */}
              {/* </div>
            <div className="font-ClashDisplay font-medium text-xl ml-3 mb-1">
              {outgoingLabel}
            </div> */}
            </div>
            <div
              className={`interacting-items ${
                isApproval ? 'approving-items' : 'sending-items'
              }`}
            >
              {outgoing}
            </div>
          </div>
        </>
      )}
      {/* If no outgoing txns, show counterparty card with details on who txferred the asset to you */}
      {incomingTxs.length === 1 && !outgoingTxs.length && (
        <div className="interacting-row sending-content">
          <div className="interacting-row-header flex align-center justify-space-between">
            <div className="transaction-type">
              <span>From</span>
            </div>
          </div>
          <SpenderCard
            network={hexChainId}
            logo={undefined}
            spender={
              incomingTxs[0]?.from ??
              eipGlobalInformation?.contractAddress ??
              'unknown'
            }
          />
        </div>
      )}
      {/* If we have transactions going in or out, show the divider */}
      {showDivider && <TransactionDivider />}
      {/* If no incoming txns, show counterparty card with details on who the recipient is */}
      {(outgoingTxs.length === 1 && !incomingTxs.length) ||
      ['erc721Approve', 'erc20Approve', 'erc1155Approve'].includes(
        transactionType
      ) ? (
        <div className="interacting-row approving-content">
          <div className="interacting-row-header flex align-center justify-space-between">
            <div className="transaction-type">
              <span>{isApproval ? incomingLabel : 'Sending To'}</span>
            </div>
          </div>
          <SpenderCard
            network={hexChainId}
            logo={undefined}
            spender={
              isApproval && eipGlobalInformation.spender
                ? eipGlobalInformation.spender
                : outgoingTxs[0]?.to ?? 'unknown'
            }
          />
        </div>
      ) : (
        <></>
      )}
      {/* If incoming txs > 1, show the regular view */}
      {!!incomingTxs.length && (
        <>
          <div className="interacting-row-header flex align-center justify-space-between">
            <div className="transaction-type">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_6938_3195)">
                  <path
                    d="M13.7746 11.3334C12.6219 13.3261 10.4674 14.6667 7.99979 14.6667C4.31789 14.6667 1.33313 11.682 1.33313 8.00008C1.33313 4.31818 4.31789 1.33341 7.99979 1.33341C10.4674 1.33342 12.6219 2.67408 13.7746 4.66675M7.99984 5.33341L5.33317 8.00008M5.33317 8.00008L7.99984 10.6667M5.33317 8.00008L14.6665 8.00008"
                    stroke="#27C440"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6938_3195">
                    <rect
                      width="16"
                      height="16"
                      fill="white"
                      transform="translate(16 16) rotate(-180)"
                    />
                  </clipPath>
                </defs>
              </svg>
              <span>{incomingLabel}</span>
            </div>
            {/* <div>
              {!!validators.length && (
                <GasEstimate
                  hexChainId={eipGlobalInformation.network}
                  usdPrice={validators[0]?.usdValue ?? -1}
                  value={validators[0]?.value}
                  decimals={validators[0]?.decimals}
                />
              )}
            </div> */}
          </div>
          <div className="interacting-items receiving-items">{incoming}</div>
        </>
      )}
      {/* If we have a gas calc, show gas estimate*/}
      {/* {!!validators.length && (
        <GasEstimate
          className="mt-5 ml-4"
          hexChainId={hexChainId}
          usdPrice={validators[0]?.usdValue ?? -1}
          value={validators[0]?.value}
          decimals={validators[0]?.decimals}
        />
      )} */}
    </>
  );
};

export default Transactions;
