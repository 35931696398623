export interface DomainMapping {
  [key: string]: DomainDetails;
}

export interface DomainDetails {
  name: string;
  icon: string;
}

export const VerifiedDomains: DomainMapping = {
  'blur.io': {
    name: 'Blur',
    icon: 'blur.png',
  },
  'opensea.io': {
    name: 'OpenSea',
    icon: 'opensea.png',
  },
  'bebop.xyz': {
    name: 'Bebop Router',
    icon: 'bebop.png',
  },
  'app.uniswap.org': {
    name: 'Uniswap',
    icon: 'uniswap.png',
  },
  'app.vela.exchange': {
    name: 'Vela',
    icon: 'vela.png',
  },
  'zora.co': {
    name: 'Zora',
    icon: 'zora.png',
  },
  'app.camelot.exchange': {
    name: 'Camelot',
    icon: 'camelot.png',
  },
  'mint.joinfire.xyz': {
    name: 'Fire',
    icon: 'fire.png',
  },
  'app.velodrome.finance': {
    name: 'Velodrome',
    icon: 'velodrome.png',
  },
  'quest.philand.xyz': {
    name: 'Phi Land',
    icon: 'philand.png',
  },
  'app.manifold.xyz': {
    name: 'Maniforld',
    icon: 'manifold.png',
  },
  'lenster.xyz': {
    name: 'Lenster',
    icon: 'lenster.png',
  },
  'galxe.com': {
    name: 'Galxe',
    icon: 'galxe.png',
  },
  'traderjoexyz.com': {
    name: 'Trader Joe XYZ',
    icon: 'traderJoe.png',
  },
  'etherscan.io': {
    name: 'Etherscan',
    icon: 'etherscan.png',
  },
  'app.gmx.io': {
    name: 'GMX',
    icon: 'gmx.png',
  },
  'rabbithole.gg': {
    name: 'Rabbithole',
    icon: 'rabbithole.png',
  },
  'app.silo.finance': {
    name: 'Silo',
    icon: 'silo.png',
  },
  'app.sushi.com': {
    name: 'Sushi',
    icon: 'sushiswap.png',
  },
  'app.1inch.io': {
    name: '1inch',
    icon: '1inch.png',
  },
  'land.philand.xyz': {
    name: 'Phi Land',
    icon: 'philand.png',
  },
  'stargate.finance': {
    name: 'Stargate',
    icon: 'stargate.png',
  },
  'revoke.cash': {
    name: 'Revoke.cash',
    icon: 'revokecash.png',
  },
  'swap.defillama.com': {
    name: 'DeFi Llama',
    icon: 'defillama.png',
  },
  'curve.fi': {
    name: 'Curve',
    icon: 'curve.png',
  },
  'mirror.xyz': {
    name: 'Mirror',
    icon: 'mirror.png',
  },
  'quickswap.exchange': {
    name: 'Quickswap',
    icon: 'quickswap.png',
  },
  'app.ens.domains': {
    name: 'ENS',
    icon: 'ens.png',
  },
  'arb.id': {
    name: 'Arb ID',
    icon: 'arbId.png',
  },
  'app.radiant.capital': {
    name: 'Radiant',
    icon: 'radient.png',
  },
  'app.hop.exchange': {
    name: 'Hop',
    icon: 'hop.png',
  },
  'zonic.app': {
    name: 'Zonic',
    icon: 'zonic.png',
  },
  'alienswap.xyz': {
    name: 'Alien Swap',
    icon: 'alienSwap.png',
  },
  'sonne.finance': {
    name: 'Sonne',
    icon: 'sonneFinance.png',
  },
  'app.pooltogether.com': {
    name: 'Pool Together',
    icon: 'pool.png',
  },
  'app.superfluid.finance': {
    name: 'Superfluid',
    icon: 'superfluid.png',
  },
  'kyberswap.com': {
    name: 'Kyberswap',
    icon: 'kyber.png',
  },
  'solidlizard.finance': {
    name: 'Solid Lizard',
    icon: 'solidLizard.png',
  },
  'kwenta.eth.limo': {
    name: 'Kwenta',
    icon: 'kwenta.png',
  },
  'app.openocean.finance': {
    name: 'Open Ocean',
    icon: 'openOcean.png',
  },
  'sharkyswap.xyz': {
    name: 'SharkySwap',
    icon: 'sharkySwap.png',
  },
  'app.aave.com': {
    name: 'Aave',
    icon: 'aave.png',
  },
  'app.zyberswap.io': {
    name: 'Zyberswap',
    icon: 'zyberSwap.png',
  },
  'vote.optimism.io': {
    name: 'Vote Optimism',
    icon: 'optimism.png',
  },
  'app.quest3.xyz': {
    name: 'Quest3',
    icon: 'quest3.png',
  },
  'verse.aavegotchi.com': {
    name: 'Aavegotchi',
    icon: 'aaveGotchi.png',
  },
  'wallet.dosi.world': {
    name: 'Dosi',
    icon: 'dosi.png',
  },
  'thalesmarket.io': {
    name: 'Thales',
    icon: 'thales.png',
  },
  'www.gem.xyz': {
    name: 'Gem',
    icon: 'gem.png',
  },
  'mint.fun': {
    name: 'Mint Fun',
    icon: 'mintFun.png',
  },
  'bookmaker.xyz': {
    name: 'Bookmaker',
    icon: 'bookmaker.png',
  },
  'www.withbacked.xyz': {
    name: 'Backed',
    icon: 'backed.png',
  },
  'trade.d5.xyz': {
    name: 'd5',
    icon: 'd5.png',
  },
  'www.0xhoneyjar.xyz': {
    name: 'The Honey Jar',
    icon: 'theHoneyJar.png',
  },
  'app.odos.xyz': {
    name: 'Odos',
    icon: 'odos.png',
  },
  'bridge.arbitrum.io': {
    name: 'Arbitrum Bridge',
    icon: 'arbitrum.png',
  },
  'www.sushi.com': {
    name: 'Sushi',
    icon: 'sushiswap.png',
  },
  'app.klimadao.finance': {
    name: 'Kilma Dao',
    icon: 'klimaDao.png',
  },
  'foundation.app': {
    name: 'Foundation',
    icon: 'foundation.png',
  },
  'mcpepes.com': {
    name: 'McPeps',
    icon: 'mcpepes.png',
  },
  'transferto.xyz': {
    name: 'Jumper',
    icon: 'jumper.png',
  },
  'sunflower-land.com': {
    name: 'Sunflower Land',
    icon: 'sunflowerLand.png',
  },
  'app.angle.money': {
    name: 'Angle',
    icon: 'angle.png',
  },
  'clique.social': {
    name: 'Clique',
    icon: 'clique.png',
  },
  'app.slingshot.finance': {
    name: 'Slingshot',
    icon: 'slingshot.png',
  },
  'echelon.io': {
    name: 'Echelon',
    icon: 'echelon.png',
  },
  'core.allbridge.io': {
    name: 'Allbridge',
    icon: 'alibridge.png',
  },
  'www.alien.fi': {
    name: 'Alien',
    icon: 'alienfi.png',
  },
  'boomland.io': {
    name: 'Boomland',
    icon: 'boomland.png',
  },
  'bridge.connext.network': {
    name: 'Connext',
    icon: 'connext.png',
  },
  'qx.app': {
    name: 'Quix',
    icon: 'quix.png',
  },
  'www.gumball.fi': {
    name: 'Gumball',
    icon: 'gumbali.png',
  },
  'app.balancer.fi': {
    name: 'Balancer',
    icon: 'balancer.png',
  },
  'beta.gem.xyz': {
    name: 'Gem',
    icon: 'gem.png',
  },
  'trove.treasure.lol': {
    name: 'Trove',
    icon: 'trove.png',
  },
  'studio.manifold.xyz': {
    name: 'Manifold',
    icon: 'manifold.png',
  },
  'gains.trade': {
    name: 'Gains',
    icon: 'gains.png',
  },
  'delegate.cash': {
    name: 'delegate.cash',
    icon: 'delegateCash.png',
  },
  'bridgeworld.treasure.lol': {
    name: 'Bridgeworld',
    icon: 'bridgeWorld.png',
  },
  'www.app.rage.trade': {
    name: 'Rage Trade',
    icon: 'rageTrade.png',
  },
  'www.cyberkongz.com': {
    name: 'Cyberkongz',
    icon: 'cyberkongz.png',
  },
  'approvals.xyz': {
    name: 'Approvals.xyz',
    icon: 'approvalsxyz.png',
  },
  'app.toucan.earth': {
    name: 'Toucan',
    icon: 'toucan.png',
  },
  'jumper.exchange': {
    name: 'Jumper',
    icon: 'jumper.png',
  },
  'stake.lido.fi': {
    name: 'Lido',
    icon: 'lido.png',
  },
  'app.lyra.finance': {
    name: 'Lyra',
    icon: 'lyra.png',
  },
  'clipper.exchange': {
    name: 'Clipper',
    icon: 'clipper.png',
  },
  'swap.cow.fi': {
    name: 'Cow Swap',
    icon: 'cowswap.png',
  },
  'x2y2.io': {
    name: 'x2y2',
    icon: 'x2y2.png',
  },
  'www.matcha.xyz': {
    name: 'Matcha',
    icon: 'matcha.png',
  },
  'sudoswap.xyz': {
    name: 'Sudoswap',
    icon: 'sudoswap.png',
  },
  'zapper.xyz': {
    name: 'Zapper',
    icon: 'zapper.png',
  },
  'beta.zapper.xyz': {
    name: 'Zapper',
    icon: 'zapper.png',
  },
  'rarible.com': {
    name: 'Rarible',
    icon: 'rarible.png',
  },
  'nouns.wtf': {
    name: 'Nouns',
    icon: 'nouns.png',
  },
  'snapshot.org': {
    name: 'Snapshot',
    icon: 'snapshot.png',
  },
  'app.abracadabra.money': {
    name: 'Abracadabra',
    icon: 'abracadabra.png',
  },
  'harpie.io': {
    name: 'Harpie',
    icon: 'harpie.png',
  },
  'lilnouns.wtf': {
    name: 'Lil Nouns',
    icon: 'lilNouns.png',
  },
};
