import { useContext, useEffect, useState } from 'react';
//import { version } from '../../../package.json';
import { SimulationContext } from '../../contexts/SimulationContext';
import Layout from '../Containers/Layout';
//import InteractionInfoView from '../Views/InteractionInfoView';
import WalletChangesView from '../Views/WalletChangesView';
//import Sunset from '../Banner/Sunset';
import BannerView from '../Views/BannerView';
import logoSentinel3 from 'images/logo-sentinel3.svg';
import logoSentinel3D from 'images/logo-sentinel3-d.svg';
//import GasEstimate from './Transfers/GasEstimate';
import {
  freeVersionIcon,
  proVersionIcon,
  darkModeIcon,
  lightModeIcon,
  //verifiedIcon,
} from 'helpers/icons/icons';
import { formatCurrency, formatUSD } from 'helpers/methods';
//import { SplitNetBalanceChanges } from 'helpers/methods';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional

////console.log('Simulator version : ', version);

/*
function jsonToBase64(jsonData: any) {
  const jsonString = JSON.stringify(jsonData);
  const textEncoder = new TextEncoder();
  const encodedData = textEncoder.encode(jsonString);
  const base64Data = btoa(String.fromCharCode(...(encodedData as any)));
  return base64Data;
}*/

const Simulator: React.FC = () => {
  const { eipGlobalInformation, transactionSimulation, transactionType } =
    useContext(SimulationContext);
  const [theme, setTheme] = useState(eipGlobalInformation?.theme);
  const [isSimulationEnabled, setIsSimulationEnabled] = useState(true);
  const [bannerClass, setBannerClass] = useState('upgrade');
  const [bannerText, setBannerText] = useState('Upgrade Now');

  const [userVersion, setUserVersion] = useState(
    eipGlobalInformation?.isFreeUser ? 'Free' : 'Pro'
  );
  const [isFreeUser, setIsFreeUser] = useState(
    eipGlobalInformation?.isFreeUser
  );

  //const referredBy = null;
  /*
  const CONTROL_PANEL_URL = 'https://app-dev.kerberus.com';
*/

  useEffect(() => {
    if (theme == 'dark') {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [theme]);

  const changeTheme = () => {
    let newTheme: 'light' | 'dark' = theme == 'light' ? 'dark' : 'light';
    setTheme(newTheme);

    window.parent.postMessage(
      {
        type: 'THEME_SWITCH_CHANGED',
        payload: newTheme,
      },
      '*'
    );
  };

  function transmitDecision(approveTransaction: boolean) {
    window.parent.postMessage(
      {
        type: 'md_translator',
        sourceUrl: eipGlobalInformation?.sourceUrl,
        status: approveTransaction, // or 'false' depending on the condition you want to test
      },
      '*'
    );
  }

  let estimatedGas: string | number = 0;
  ////console.log('transactionSimulation', transactionSimulation);

  if (transactionSimulation?.netBalanceChanges) {
    transactionSimulation?.netBalanceChanges.forEach((x) => {
      if (x.to == 'validators') {
        estimatedGas =
          x.usdValue !== -1
            ? formatUSD(x.usdValue || 0)
            : formatCurrency(x.value.toString(), x.token?.decimals);
      }
    });
  }

  function handleUpgradeClick() {
    window.parent.postMessage(
      {
        type: 'UPGRADE_PLAN',
      },
      '*'
    );
    setBannerClass('upgraded');
    setBannerText('Upgraded');

    setUserVersion('Pro');
    setIsFreeUser(false);
  }

  return (
    <Layout>
      {/*<Sunset />*/}
      <div className="header-wrapper">
        <div className="header flex flex-column">
          <div className="logo-container flex">
            <div className="logo">
              <a
                className="flex align-center justify-center"
                href="http://kerberus.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={logoSentinel3} className="no-dark" />
                <img src={logoSentinel3D} className="dark" />
              </a>
            </div>
            <div className="spacer flex-1"></div>
            <div className="flex header-right">
              <div className="tag-version">
                {isFreeUser ? freeVersionIcon : proVersionIcon}
                <span>{userVersion}</span>
              </div>
              {!isFreeUser && (
                <div className="tag-version">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.49954 9H21.4995M9.99954 3L7.99954 9L11.9995 20.5L15.9995 9L13.9995 3M12.6141 20.2625L21.5727 9.51215C21.7246 9.32995 21.8005 9.23885 21.8295 9.13717C21.8551 9.04751 21.8551 8.95249 21.8295 8.86283C21.8005 8.76114 21.7246 8.67005 21.5727 8.48785L17.2394 3.28785C17.1512 3.18204 17.1072 3.12914 17.0531 3.09111C17.0052 3.05741 16.9518 3.03238 16.8953 3.01717C16.8314 3 16.7626 3 16.6248 3H7.37424C7.2365 3 7.16764 3 7.10382 3.01717C7.04728 3.03238 6.99385 3.05741 6.94596 3.09111C6.89192 3.12914 6.84783 3.18204 6.75966 3.28785L2.42633 8.48785C2.2745 8.67004 2.19858 8.76114 2.16957 8.86283C2.144 8.95249 2.144 9.04751 2.16957 9.13716C2.19858 9.23885 2.2745 9.32995 2.42633 9.51215L11.385 20.2625C11.596 20.5158 11.7015 20.6424 11.8279 20.6886C11.9387 20.7291 12.0603 20.7291 12.1712 20.6886C12.2975 20.6424 12.4031 20.5158 12.6141 20.2625Z"
                      stroke="#356AF3"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span>$30k Covered</span>
                </div>
              )}

              <div className="free-dark-mode" onClick={changeTheme}>
                {theme == 'dark' ? lightModeIcon : darkModeIcon}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        {isFreeUser && (
          <div className="appFreeBanner">
            <div className="flex flex-1 appFreeContent">
              <div className="appFreeLeft flex">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 11.5L11 13.5L15.5 8.99999M20 12C20 16.9084 14.646 20.4784 12.698 21.6149C12.4766 21.744 12.3659 21.8086 12.2097 21.8421C12.0884 21.8681 11.9116 21.8681 11.7903 21.8421C11.6341 21.8086 11.5234 21.744 11.302 21.6149C9.35396 20.4784 4 16.9084 4 12V7.21759C4 6.41808 4 6.01833 4.13076 5.6747C4.24627 5.37113 4.43398 5.10027 4.67766 4.88552C4.9535 4.64243 5.3278 4.50207 6.0764 4.22134L11.4382 2.21067C11.6461 2.13271 11.75 2.09373 11.857 2.07827C11.9518 2.06457 12.0482 2.06457 12.143 2.07827C12.25 2.09373 12.3539 2.13271 12.5618 2.21067L17.9236 4.22134C18.6722 4.50207 19.0465 4.64243 19.3223 4.88552C19.566 5.10027 19.7537 5.37113 19.8692 5.6747C20 6.01833 20 6.41808 20 7.21759V12Z"
                    stroke="#F9BD64"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <div>
                  <b>Get Sentinel3 Pro</b>
                  <span></span>
                </div>
              </div>
              <div className={bannerClass} onClick={handleUpgradeClick}>
                {bannerText}
              </div>
            </div>
          </div>
        )}

        <div className="interacting flex flex-column">
          <div className="interacting-content flex flex-column">
            <BannerView
              eipGlobalInformation={eipGlobalInformation}
              transactionSimulation={transactionSimulation}
              transactionType={transactionType}
            />

            <WalletChangesView
              eipGlobalInformation={eipGlobalInformation}
              transactionSimulation={transactionSimulation}
              transactionType={transactionType}
            />
          </div>
        </div>

        <div className="footer flex flex-column">
          {!isFreeUser && (
            <div style={{ padding: '20px 20px 16px 20px' }}>
              <div className="settings-group flex flex-column align-center">
                <div className="simulate-transactions settings-attrib flex justify-between align-center">
                  <div className="setting-label">
                    <span className="flex-1">Turn off simulations</span>

                    <span className="recommended-simulation-off">
                      Recommended
                      <Tippy
                        content="Unlike other security solutions, Sentinel3 detects scams automatically without requiring any manual review. Additionally, hiding simulations does not affect its detection capabilities."
                        duration={[0, 500]}
                        delay={100}
                        theme="light"
                        animation="fade"
                        arrow={true}
                        maxWidth={600}
                        offset={[0, 15]}
                      >
                        <span>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_7266_5040)">
                              <path
                                d="M7.99967 10.6666V7.99992M7.99967 5.33325H8.00634M14.6663 7.99992C14.6663 11.6818 11.6816 14.6666 7.99967 14.6666C4.31778 14.6666 1.33301 11.6818 1.33301 7.99992C1.33301 4.31802 4.31778 1.33325 7.99967 1.33325C11.6816 1.33325 14.6663 4.31802 14.6663 7.99992Z"
                                stroke="#8789A6"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_7266_5040">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </Tippy>
                    </span>
                  </div>

                  <button
                    className={`button secondary-button ${
                      isSimulationEnabled
                        ? 'disable-simulation-button'
                        : 'enable-simulation-button'
                    }`}
                    onClick={() => {
                      setIsSimulationEnabled(!isSimulationEnabled);
                      window.parent.postMessage(
                        {
                          type: 'SIMULATION_SWITCH_CHANGED',
                          payload: isSimulationEnabled,
                        },
                        '*'
                      );
                    }}
                  >
                    {isSimulationEnabled ? 'OFF' : 'ON'}
                  </button>
                  {/*
                                    <label className="switch">
                    <input
                      type="checkbox"
                      checked={isSimulationEnabled}
                      onChange={(event) => {
                        setIsSimulationEnabled(event.target.checked);
                        const isChecked = !event.target.checked;
                        window.parent.postMessage(
                          {
                            type: 'SIMULATION_SWITCH_CHANGED',
                            payload: isChecked,
                          },
                          '*'
                        );
                      }}
                    />
                    <span className="slider round"></span>
                                      </label>
                    */}
                </div>
              </div>
            </div>
          )}

          {isFreeUser ? (
            <div style={{ padding: '10px 10px 10px 10px' }}>
              <div className="expenses flex flex-column">
                <div className="expenses-item feeExpense flex justify-between">
                  <div>Fees</div>
                  <span>
                    {eipGlobalInformation.fees
                      ? `${eipGlobalInformation.fees}%`
                      : '0%'}
                  </span>
                </div>

                <div className="expenses-item gasExpense flex justify-between">
                  <div>Gas</div>
                  <span>{estimatedGas ? `${estimatedGas}` : '$0'}</span>
                </div>
              </div>
            </div>
          ) : (
            <div className="expenses flex flex-column">
              <div className="expenses-item feeExpense flex justify-between">
                <div>Fees</div>
                <span>
                  {eipGlobalInformation.fees
                    ? `${eipGlobalInformation.fees}%`
                    : '0%'}
                </span>
              </div>

              <div className="expenses-item gasExpense flex justify-between">
                <div>Gas</div>
                <span>{estimatedGas ? `${estimatedGas}` : '$0'}</span>
              </div>
            </div>
          )}

          <div className="buttons flex align-center justify-center">
            <button
              className="reject-reset-button button secondary-button flex-1"
              onClick={() => transmitDecision(false)}
            >
              Reject
            </button>
            <button
              className="continue-button button primary-button flex-1"
              onClick={() => transmitDecision(true)}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Simulator;
